import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This section contains React Ensemble's API reference.`}</p>
    <p>{`Changes made to the public API are considered breaking changes and will cause a major version bump: `}<inlineCode parentName="p">{`v1.x`}</inlineCode>{` to `}<inlineCode parentName="p">{`v2.x`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "contents"
    }}>{`Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/controller"
        }}>{`Controller`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/lib"
        }}>{`Lib`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/simplecontrolpanel"
        }}>{`SimpleControlPanel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/timeline"
        }}>{`Timeline`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/trackutils"
        }}>{`TrackUtils`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/types"
        }}>{`Type Definitions`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      